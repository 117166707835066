const generateTraceParent = () => {
  return `00-${generateRandomHex(32)}-${generateRandomHex(16)}-01`;
};

const generateRandomHex = (size: number) => {
  return [...Array(size)]
    .map(() => Math.floor(Math.random() * 16).toString(16))
    .join('');
};

export default generateTraceParent;

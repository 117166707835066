class CustomSpinner extends HTMLElement {
  constructor() {
    super();
    const shadow = this.attachShadow({ mode: 'open' });

    const style = document.createElement('style');
    style.textContent = `
          .loader {
              z-index: 2000;
              width: 50px;
              aspect-ratio: 1;
              --_c: no-repeat radial-gradient(farthest-side, #63C360 92%, #0000);
              background: var(--_c) top, var(--_c) left, var(--_c) right, var(--_c) bottom;
              background-size: 12px 12px;
              animation: l7 1s infinite;
          }

          @keyframes l7 {
              to {
                  transform: rotate(0.5turn);
              }
          }
      `;

    const loader = document.createElement('div');
    loader.classList.add('loader');

    shadow.appendChild(style);
    shadow.appendChild(loader);
  }
}

customElements.define('custom-spinner', CustomSpinner);

class GlobalSpinnerController {
  overlay: HTMLDivElement;

  constructor() {
    this.overlay = document.createElement('div');
    this.overlay.style.position = 'fixed';
    this.overlay.style.top = '0';
    this.overlay.style.left = '0';
    this.overlay.style.width = '100%';
    this.overlay.style.height = '100%';
    this.overlay.style.display = 'none';
    this.overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
    this.overlay.style.zIndex = '1000';
    this.overlay.style.justifyContent = 'center';
    this.overlay.style.alignItems = 'center';
    this.overlay.style.flexDirection = 'column';

    document.body.appendChild(this.overlay);
  }

  showSpinner() {
    if (!this.overlay.querySelector('custom-spinner')) {
      const spinner = document.createElement('custom-spinner');
      this.overlay.appendChild(spinner);
    }
    this.overlay.style.display = 'flex';
  }

  hideSpinner() {
    const spinner = this.overlay.querySelector('custom-spinner');
    if (spinner) {
      this.overlay.removeChild(spinner);
    }
    this.overlay.style.display = 'none';
  }
}

const globalSpinnerController = new GlobalSpinnerController();

export { globalSpinnerController };
